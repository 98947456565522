<template>
  <el-collapse v-model="filter" @change="handleChange" class="mt-3 pb-3">
    <el-row class="m-0 filter">
        <el-col :span="10" class="ml-2 filter--container">
            <span class="mr-2" style="min-width: 10rem">Наименование:</span>
            <remote-search-input
            id="energy_objects-filter--name_input"
            v-model="filterModel.nameFilter"
            searchUrl="/api/energyObjects/SearchByName"
            />
        </el-col>
        <el-col :span="10" class="filter--container ml-2">
          <span class="mr-2">Скрыть зарубежные объекты:</span>
          <el-checkbox v-model="filterModel.isRuFilter" />
        </el-col>  
    </el-row>    
      <el-row class="mt-2 filter">      
        <el-col :span="10" class="filter--container ml-2">
            <span class="mr-2">Отображать объекты локальной НСИ:</span>        
            <el-checkbox v-model="filterModel.isLocalNsiFilter"/>
        </el-col>
      </el-row>
  </el-collapse>
</template>

<script>
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput';

export default {
  name: 'EnergyObjectsFilter',
  props: {
    filterModel: { type: Object, default: () => {} },
  },
  components: { RemoteSearchInput },
  data() {
    return {
      filter: ['1'],
    };
  },
  methods: {
    handleChange(val) {
      const objectLength = Object.keys(val);
      if (objectLength.length > 0) {
        this.$emit('active-change', true);
       } else {
        this.$emit('active-change', false);
      }
    }
  }
};
</script>

<style scoped>

</style>
