<template>
  <div class="my-flex-container-column">
    <second-equipment-panels-filter @active-change="filterActive = $event" :filter-model="filterModel" @clear-filters="clearFilters"/>
    <second-equipment-panels-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import SecondEquipmentPanelsFilter from '@/views/Global/SecondEquipmentPanels/SecondEquipmentPanelsFilter.vue';
import SecondEquipmentPanelsTable from '@/views/Nsi/SecondEquipmentPanels/SecondEquipmentPanelsTable';

export default {
  name: 'SecondEquipmentPanels',
  components: { SecondEquipmentPanelsTable, SecondEquipmentPanelsFilter },
  data() {
    return {
      filterModel: {
        nameFilter: '',
        eoIdFilter: '',
        skipEoObject: false,
        isLocalNsiFilter: false,
        productAssetModelIdFilter: '',
        duOrganizationName: '',
        dvOrganizationName: '',
        baseVoltageFilter: '',
        eoRegionFilter: [],
        ownerOrganizationsFilter: [],
        registrationGroupFilter: [],
        executionsFilter: [],
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        technicalOrganizationsFilter: [],
        startCommissioningTimeRange: '',
        endCommissioningTimeRange: '',
        startDecommissioningTimeRange: '',
        endDecommissioningTimeRange: '',
        showDecommissionedSE: null
      },
      filterActive: true
    };
  },
  methods: {
    clearFilters() {
      this.filterModel = {
        nameFilter: '',
        eoIdFilter: '',
        skipEoObject: false,
        isLocalNsiFilter: false,
        productAssetModelIdFilter: '',
        duOrganizationName: '',
        dvOrganizationName: '',
        baseVoltageFilter: '',
        eoRegionFilter: [],
        ownerOrganizationsFilter: [],
        registrationGroupFilter: [],
        executionsFilter: [],
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        technicalOrganizationsFilter: [],
        startCommissioningTimeRange: '',
        endCommissioningTimeRange: '',
        startDecommissioningTimeRange: '',
        endDecommissioningTimeRange: '',
        showDecommissionedSE: false
      };
    }
  }
};
</script>

<style scoped>

</style>
